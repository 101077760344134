'use client'

import UIFlex from '@components/ui/UIFlex'
import { keyframes } from '@emotion/react'
import IconLoadingSpinner from 'src/assets/icons/IconLoadingSpinner'
import { useDarkModeControl } from '@hooks/useDarkModeControl'
import color from '@ui/style/color.theme'

const LoadingSpinner = () => {
	const { darkMode } = useDarkModeControl()
	const spin = keyframes`
		from {
			transform : rotate(0);
		}
		to {
			transform: rotate(360deg);
		}
	`
	return (
		<UIFlex
			justifyContent="center"
			alignItems="center"
			width="100%"
			sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 2000,
				background: darkMode ? color.colBlack : color.colWhite,
				svg: {
					animation: `${spin} 0.5s infinite linear`,
				},
			}}
		>
			<IconLoadingSpinner />
		</UIFlex>
	)
}

export default LoadingSpinner
