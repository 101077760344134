'use client'

import HomeSectionAd from '@components/ads/Home/Section'
import SectionContainer from '@components/common/Section/SectionContainer'
import LoadingContainer from '@components/layout/Loading'
import { Box } from '@mui/joy'
import MainSection, { MainSectionData } from '@page/home/_components/MainSection'
import {
	ApiResultVoCommonAreaResponseVo,
	HomeAreaSequenceResponseVo,
	LiveScheduleResponseVo,
} from '@schemas/non-auth'
import { queryLiveSchedules } from '@services/api/Live/Schedules'
import { getQueryNewHomeArea } from '@services/api/NewsHome'
import { queryAreaSeq } from '@services/api/NewsHome/AreaSeq'
import { useGetCookieHeader } from '@store/cookieHeader'
import { useGetIsLive } from '@store/isLive'
import { useQueries, useQuery } from '@tanstack/react-query'
import pxToRem from '@utils/pxToRem'
import { usePathname } from 'next/navigation'
import { Fragment, useEffect } from 'react'

export interface MainContainerProps {
	sortList?: Array<HomeAreaSequenceResponseVo>
	sectionList?: Array<ApiResultVoCommonAreaResponseVo>
	scheduleList?: Array<LiveScheduleResponseVo>
}
const MainContainerCSR = ({ sortList, sectionList, scheduleList }: MainContainerProps) => {
	const cookieHeader = useGetCookieHeader()
	const isLive = useGetIsLive()
	const pathname = usePathname()
	const { data: responseAreaSeq } = useQuery(queryAreaSeq())
	const areaSeq: Array<HomeAreaSequenceResponseVo> =
		sortList ??
		(responseAreaSeq?.data ?? []).sort(
			(a: HomeAreaSequenceResponseVo, b: HomeAreaSequenceResponseVo) =>
				a.areaDispSeq - b.areaDispSeq,
		)
	const sectionQueries = areaSeq.map(({ areaIdx, divValueIdx }) => {
		const sectionQuery = getQueryNewHomeArea(divValueIdx)
		return sectionQuery({ areaIdx }, cookieHeader)
	})
	const responseQueries = useQueries({
		queries: [...sectionQueries, queryLiveSchedules({ isMainHome: true }, cookieHeader)],
	})
	const response = responseQueries?.map((res) => res?.data)
	const liveSchedules = (scheduleList ?? response.pop()?.data) as Array<LiveScheduleResponseVo>
	const sections = sectionList ?? response
	useEffect(() => {
		responseQueries.forEach((res) => {
			res.refetch()
		})
	}, [pathname])
	return (
		<SectionContainer
			sx={(theme) => ({
				paddingTop: 0,
				[theme.breakpoints.up('xs')]: {
					paddingBottom: theme.pxToRem(48),
				},
				[theme.breakpoints.up('md')]: {
					paddingTop: theme.pxToRem(24),
				},
				[theme.breakpoints.up('lg')]: {
					paddingBottom: theme.pxToRem(64),
				},
				[theme.breakpoints.up('xl')]: {
					paddingTop: theme.pxToRem(32),
					paddingBottom: theme.pxToRem(84),
				},

				// 배경색 영역, 배경이미지 영역
				'& .set-color, & .set-bg': {
					paddingTop: { xs: pxToRem(42), md: pxToRem(48) },
					// case 1 : 타이틀 있는 경우 > margin 음수값으로 패딩영역 적어보이게 적용
					'& .sectionTitle:first-child': {
						marginTop: { xs: pxToRem(-30), md: pxToRem(-32) },
					},
					// case 2 : 타이틀 없는 경우 > 767이하
					[theme.breakpoints.down('md')]: {
						// 템플릿 종류: 1.대형이미지 리스트형, 2.대형 이미지 이슈형, 3.연관 뉴스형, 5.중형 이미지형(1), 6.중형 이미지형(2), 7.중형 이미지형(3)
						// 2-1) 메인 기사 이미지가 full 사이즈일 경우, 상단 여백 없어보이도록 처리
						'& .list-LGimageRelated, & .list-LGimageIssue, & .list-LGimageList, & .list-LGimageList, & .List-MDimage1, & .List-MDimage2, & .list-MDimage3':
							{
								'& .large-contentCard': {
									marginTop: pxToRem(-42),
								},
							},
						// 2-2) 중간에 타이틀이나 탭 있을 경우 패딩 취소
						'& * + *': {
							'& .list-LGimageRelated, & .list-LGimageIssue, & .list-LGimageList, & .list-LGimageList, & .List-MDimage1, & .List-MDimage2, & .list-MDimage3':
								{
									'& .large-contentCard': {
										marginTop: pxToRem(0),
									},
								},
						},
						// 2-3) 타이틀 있는 경우 음수 마진 취소
						'& .sectionTitle ~ [class*="list-"]': {
							'& .large-contentCard': {
								marginTop: 0,
							},
						},
					},
					// case 3 : 첫 번째 섹션일 경우 헤더와 섹션 사이 간격 없어보이도록 처리
					'&:first-child': {
						marginTop: { xs: pxToRem(0), md: pxToRem(-24), xl: pxToRem(-32) },
					},
				},

				// 7.중형 이미지형(3) 영역
				// case 1 : 767이하일때, 배경 여부와 상관없이 상단 여백 추가
				'& .list-MDimage3': {
					[theme.breakpoints.down('md')]: {
						paddingTop: pxToRem(20),
					},
				},
				// 1-1) 중간에 타이틀이나 탭 있을 경우 패딩 취소
				'& * + *': {
					'& .list-MDimage3': {
						[theme.breakpoints.down('md')]: {
							paddingTop: pxToRem(0),
						},
					},
				},

				// '& > *:first-child + *': {
				// 	[theme.breakpoints.up('xs')]: {
				// 		marginTop: `${theme.pxToRem(48)}!important`,
				// 	},
				// 	[theme.breakpoints.up('md')]: {
				// 		marginTop: `${theme.pxToRem(64)}!important`,
				// 	},
				// 	[theme.breakpoints.up('xl')]: {
				// 		marginTop: `${theme.pxToRem(64)}!important`,
				// 	},
				// },
			})}
		>
			<LoadingContainer load={!!sections.find((section) => section !== undefined)}>
				{areaSeq?.map((item, index) => {
					if (!sections[index]) {
						return null
					}
					const sectionData = sections[index]?.data
					return (
						sectionData && (
							<Fragment key={`section-${item.divValueIdx}-${item.areaIdx}`}>
								<MainSection
									data={
										{
											...item,
											...sectionData,
										} as MainSectionData
									}
									isLive={isLive}
									scheduleList={liveSchedules ?? []}
								/>
								{/* // AD 비노출 처리
								{index === 4 && (
									<Box>
										<HomeSectionAd type="pc" />
										<HomeSectionAd type="mo" />
									</Box>
								)} */}
							</Fragment>
						)
					)
				})}
			</LoadingContainer>
		</SectionContainer>
	)
}

export default MainContainerCSR
