import { ApiResultVoListHomeAreaSequenceResponseVo } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { fetchAPI } from '@utils/http/apiRequest'

export type AreaSeqResponse = CommonResponse<ApiResultVoListHomeAreaSequenceResponseVo>

const path = '/restapi/v1/get/template/news-home/area-seq'
const queryKeyString = () => ['@template/news-home/area-seq'] as const

const getQuery = async (
	cookieHeader?: string,
): Promise<ApiResultVoListHomeAreaSequenceResponseVo> => {
	return fetchAPI({ path, cookieHeader })
}

export const queryAreaSeq = (cookieHeader?: string) => ({
	queryKey: queryKeyString(),
	queryFn: () => getQuery(cookieHeader),
})

export const prefetchAreaSeq = (cookieHeader?: string) =>
	createPrefetchQuery(queryKeyString(), () => getQuery(cookieHeader))
