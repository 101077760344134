const IconLoadingSpinner = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
		>
			<path
				d="M10 24C10 31.732 16.268 38 24 38C31.732 38 38 31.732 38 24C38 16.268 31.732 10 24 10"
				stroke="#0157AA"
				strokeWidth="4"
				strokeLinecap="round"
			/>
		</svg>
	)
}

export default IconLoadingSpinner
