import { ApiResultVoListLiveScheduleResponseVo } from '@schemas/non-auth'
import { createPrefetchQuery } from '@utils/createPrefetchQuery'
import { fetchAPI } from '@utils/http/apiRequest'
import { DATE_FORMAT, dayjs } from '@utils/date'

export type LiveSchedulesResponse = CommonResponse<ApiResultVoListLiveScheduleResponseVo>
export type LiveSchedulesReqModel = {
	searchDate?: string
	isMainHome: boolean
}

const path = '/restapi/v1/get/contents/live/schedules'
const queryKeyString = (paramsKey: LiveSchedulesReqModel | null) =>
	['@contents/live/schedules', paramsKey] as const

const getQuery = async (
	props: LiveSchedulesReqModel,
	cookieHeader?: string,
): Promise<ApiResultVoListLiveScheduleResponseVo> => {
	const { searchDate } = props
	const params = new URLSearchParams({
		...(searchDate
			? { searchDate: String(searchDate) }
			: {
					searchDate: dayjs().format(DATE_FORMAT.DATE_AS_NUMBER),
			  }),
	})
	return fetchAPI({ path, params, cookieHeader })
}

export const queryLiveSchedules = (props: LiveSchedulesReqModel, cookieHeader?: string) => ({
	queryKey: [...queryKeyString(props)],
	queryFn: () => getQuery(props, cookieHeader),
})

export const prefetchLiveSchedules = (props: LiveSchedulesReqModel, cookieHeader?: string) =>
	createPrefetchQuery(queryKeyString(props), () => getQuery(props, cookieHeader))
