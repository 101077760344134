'use client'

import { ReactNode } from 'react'
import LoadingSpinner from './Spinner'

interface LoadingSpinnerProps {
	load: boolean
	children?: ReactNode
}
const LoadingContainer = ({ load, children }: LoadingSpinnerProps) => {
	return load ? children : <LoadingSpinner />
}

export default LoadingContainer
